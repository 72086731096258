import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/documentationV2/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "️-high-level-architecture"
    }}>{`🏛️ High-Level Architecture`}</h1>
    <p>{`The foundational element of the Verse system, ensuring both security and flexibility, lies in its modular basis architecture.`}</p>
    <p>{`In the near future, Verse will be able to accept variant Liquid Staking Tokens (LST) in
the market and help the LST holders to earn extra yields without compromising the fund security.`}</p>
    <p>{`The institutional-grade validator cluster is a sophisticated module to manage Ethereum validators to secure Ethereum blockchain network and earn staking rewards.
It’s the barebone of Verse system with tracked record since it’s launch in early 2023.`}</p>
    <p>{`This module is still under development, and it depends on the AMM module readiness of Swell.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      