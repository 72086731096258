import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/documentationV2/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Tag } from '@nejcm/docz-theme-extended';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "🧇-modular-key-functions"
    }}>{`🧇 Modular Key Functions`}</h1>
    <br />
Verse constitutes a modularized and multi-chain Liquid Restaking protocol. 
Thanks to its adaptable modular design, Verse can undergo continuous and rapid evolution to incorporate new technologies or changes. 
    <br />
    <br />
Below are the core modules of Verse:
    <br />
    <Tag type="info" mdxType="Tag">Staking contract</Tag>
    <br />
    <Tag type="info" mdxType="Tag">Restaking module</Tag>
    <br />
    <Tag type="info" mdxType="Tag">Swap ratio calculation</Tag>
    <br />
    <Tag type="info" mdxType="Tag">Unstaking module</Tag>
    <br />
    <Tag type="info" mdxType="Tag">DVT module</Tag>
    <br />
    <Tag type="info" mdxType="Tag">LRT module</Tag>
    <br />
    <Tag type="info" mdxType="Tag">Vault module</Tag>
    <br />
    <Tag type="info" mdxType="Tag">Restaking delegation module</Tag>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      