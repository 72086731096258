import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/documentationV2/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "️-stake--unstake-eth"
    }}>{`☄️ Stake / Unstake ETH`}</h1>
    <p>{`To stake your ETH, click 'Connect Wallet' at the top of the page to link your MetaMask to the dApp.
Once connected, the deposit/withdraw function will be available. `}</p>
    <p>{`You will receive tokens when you deposit it and it will accrue staking rewards over time by growing
in token value with AMM model. Tokens can also be sold and traded on various DEXs and CEXs if there
is liquidity available for the trade and can also be used to provide liquidity to earn additional yield.`}</p>
    <p>{`The time it takes to unstake tokens is largely dependent on the Ethereum blockchain and vault that
you choose. There will be a waiting time for validators to access the ETH they wish to unstake, as
there is only a single queue for both full and partial withdrawals on the blockchain.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      