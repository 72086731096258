import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/documentationV2/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Hint } from '@nejcm/docz-theme-extended';
import { Tag } from '@nejcm/docz-theme-extended';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "🧱-introduction-to-multi-asset-liquid-staking"
    }}>{`🧱 Introduction to Multi-Asset Liquid Staking`}</h1>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`Lifecycle of Staking Contract: `}<br /></p>
    <Tag type="success" mdxType="Tag">Mint</Tag> 
    <Tag type="success" mdxType="Tag">Delegation</Tag> 
    <Tag type="success" mdxType="Tag">Push Rewards</Tag> 
    <Tag type="success" mdxType="Tag">Redeem</Tag> 
    <Tag type="success" mdxType="Tag">Claim</Tag> 
    <Tag type="success" mdxType="Tag">Validators Exit</Tag> 
    <Tag type="success" mdxType="Tag">Register Validators</Tag> <br />
    <h2 {...{
      "id": "core"
    }}>{`Core`}</h2>
    <p>{`There are several basic principles that shape the design of the Verse Permissionless Vaults protocol:`}</p>
    <pre><code parentName="pre" {...{}}>{`1. Liquidity put into protocol can always be withdrawn (but, maybe not through 
one block, because we have a withdrawal limit for a single block in order to 
protect us from potential attacks). That is governance, strategy or any other 
entity cannot preclude a Liquidity Provider from withdrawing the funds.

2. Actions of Strategist or Governance that change substantial protocol parameters
 undergo some time between the moment changes are proposed in the contract (so anybody
  can see this proposal) and the moment they are effectively staged (typically 1 day).

3. Strategy actions are limited by the permissions of the particular Vault System.

4. Everyone can create a Vault System ready for accepting deposits by Liquidity Providers
 and managing the liquidity by the Strategy.

5. The Strategy is an arbitrary contract constrained by the Vault System 
permissions system
`}</code></pre>
    <h2 {...{
      "id": "vault-groups"
    }}>{`Vault groups`}</h2>
    <p>{`A vault group is a set of entities that manage Vaults of a specific Vault kind.
It is used to create a new Vault or manage Vault params. It has 3 main entities:`}</p>
    <pre><code parentName="pre" {...{}}>{`1. Vault Governance is a contract that contains a set of parameters for a specific Vault
 kind managed by a Strategist or Governance. The params are defined by being:
    - Being instant or delayed (changes take into effect after some delay, which is set 
    in the Protocol Governance)
    - Being applied to all Vaults in the Vault Group System or to some specific vaults
    - Being managed by a Strategist or by Governance.
2. Vault Factory is a singleton instance of a certain Vault kind, serving as a 
prototype for all vaults of the Vault Group
3. Vault instances, where each new vault is created using EIP-1167 lightweight 
proxy clone from Vault Factory
`}</code></pre>
    <h2 {...{
      "id": "vault-governance"
    }}>{`Vault Governance`}</h2>
    <p>{`We have several Vault kinds, and, as stated above, any Vault kind has its own Vault Governance. Any governance, in its turn,
has its own parameters.`}</p>
    <h2 {...{
      "id": "how-are-staking-rewards-are-distributed"
    }}>{`How are staking rewards are distributed`}</h2>
    <Hint type="success" mdxType="Hint">
Earning ETH staking rewards is just one of the benefits for holding; there are certainly additional rewards for every holder. 
    </Hint>
As all the ETH deposited through this protocol is pooled together to provide the 32 ETH for each node, the node will receive 
the rewards and automatically distribute it across all staking participants based on how much ETH they staked of the 32 ETH 
total, i.e. if you staked 3.2 ETH you will receive 10% of each reward. Each time rewards are distributed (every block), they 
will be added to the initial stake amount, eventually compounding future earnings as more nodes are added, while immediately 
increasing the value of the tokens representing each stake
    <h2 {...{
      "id": "whats-the-minimum-deposit"
    }}>{`What's the minimum deposit`}</h2>
    <Hint type="info" mdxType="Hint">
It depends on the specific vault, you can see the Limits on the Dashboard or Vaults page, there will be indicated the minimum 
and maximum deposit amount.
    </Hint>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      