import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/documentationV2/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Ref } from '@nejcm/docz-theme-extended';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-liquid-restaking-with-swell"
    }}>{`💧 Liquid Restaking with Swell`}</h1>
    <p>{`Liquid restaking is liquid staking combined with restaking on Swell in one token.
This allows rswETH holders to earn rewards not only from ETH staking but also from restaking on Verse, without compromising liquidity with our partner booster multiplier.`}</p>
    <Ref name="Learn more here" to="/cntnmdx/introswell" mdxType="Ref" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      