import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/documentationV2/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Tag } from '@nejcm/docz-theme-extended';
import { Hint } from '@nejcm/docz-theme-extended';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-introduction-to-swell"
    }}>{`🔍 Introduction to Swell`}</h1>
    <p>{`Swell Network is a decentralized, non-custodial liquid staking platform that is designed to provide users with liquid staking services on Ethereum.
Unlike other traditional staking platforms, Swell places emphasis on restaking, which allows stakers to amplify their yield through additional layers
of rewards in the Ethereum and broader DeFi ecosystem. Here’s a detailed breakdown of the Swell `}<br /></p>
    <Tag type="info" mdxType="Tag">Key Features of Swell Finance:</Tag>  <br />
    <h2 {...{
      "id": "security-and-audits"
    }}>{`Security and Audits`}</h2>
    <p>{`Swell smart contracts undergo regular security audits to ensure the safety of users' funds. Swell also uses a decentralized network of node operators,
reducing centralization risks.`}</p>
    <h2 {...{
      "id": "dao"
    }}>{`DAO`}</h2>
    <p>{`Swell is governed by a decentralized autonomous organization (DAO). rswETH token holders have voting power in protocol decisions, which gives the community
control over the platform's direction and policies.`}</p>
    <h2 {...{
      "id": "flexible-exit"
    }}>{`Flexible Exit`}</h2>
    <p>{`With Swell, users are not locked into staking indefinitely. By holding and trading liquid staking tokens like stETH, users can effectively exit their
staking position by selling their stETH without having to wait for unstaking periods, which can be lengthy on some networks.`}</p>
    <h2 {...{
      "id": "community-and-ecosystem-support"
    }}>{`Community and Ecosystem Support`}</h2>
    <p>{`Swell has become one of the most widely adopted liquid staking solutions, with strong community support and integration with numerous DeFi projects
and protocols.`}</p>
    <Tag type="info" mdxType="Tag">Key Advantages:</Tag> <br />
    <h2 {...{
      "id": "liquidity"
    }}>{`Liquidity`}</h2>
    <p>{`Stakers maintain liquidity, meaning they can use or trade their staked assets, which would otherwise be locked.`}</p>
    <h2 {...{
      "id": "accessibility"
    }}>{`Accessibility`}</h2>
    <p>{`Swell eliminates the high barrier of entry to staking on certain networks, such as Ethereum.`}</p>
    <h2 {...{
      "id": "composability"
    }}>{`Composability`}</h2>
    <p>{`Liquid staking tokens like stETH are integrated into various DeFi protocols, enabling further yield generation.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      