import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/documentationV2/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { InlineCode } from '@nejcm/docz-theme-extended';
import { Hint } from '@nejcm/docz-theme-extended';
import { Tabs } from '@nejcm/docz-theme-extended';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-intro-to-liquid-staking"
    }}>{`💡 Intro to Liquid Staking`}</h1>
    <p>{`PoS chains will be an integral part of the future of crypto, and become the foundation layer of which DeFi and metaverses will be built on.`}</p>
    <p>{`However, PoS comes with some drawbacks for those wanting to participate directly as validators:`}</p>
    <pre><code parentName="pre" {...{}}>{`1. It requires technical know-how to set up and operate

2. 32ETH is a significant barrier for regular token holders to participate in
    POS validation

3. Staked tokens are locked up and become illiquid assets
`}</code></pre>
    <p>{`To solve these issues, liquid staking protocols were born. Liquid staking abstracts the staking of tokens from running a validator node.`}</p>
    <p>{`In exchange for their tokens, depositors receive a representative token from the protocol which is a claim on the tokens they have staked.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      