const { hot } = require("react-hot-loader/root")

// prefer default export if available
const preferDefault = m => (m && m.default) || m


exports.components = {
  "component---cache-dev-404-page-js": hot(preferDefault(require("/home/documentationV2/demo/.docz/.cache/dev-404-page.js"))),
  "component---src-cntnmdx-btp-mdx": hot(preferDefault(require("/home/documentationV2/demo/src/cntnmdx/btp.mdx"))),
  "component---src-cntnmdx-feeseth-mdx": hot(preferDefault(require("/home/documentationV2/demo/src/cntnmdx/feeseth.mdx"))),
  "component---src-cntnmdx-highlevel-mdx": hot(preferDefault(require("/home/documentationV2/demo/src/cntnmdx/highlevel.mdx"))),
  "component---src-cntnmdx-intromals-mdx": hot(preferDefault(require("/home/documentationV2/demo/src/cntnmdx/intromals.mdx"))),
  "component---src-cntnmdx-introrocket-mdx": hot(preferDefault(require("/home/documentationV2/demo/src/cntnmdx/introrocket.mdx"))),
  "component---src-cntnmdx-introstake-mdx": hot(preferDefault(require("/home/documentationV2/demo/src/cntnmdx/introstake.mdx"))),
  "component---src-cntnmdx-introswell-mdx": hot(preferDefault(require("/home/documentationV2/demo/src/cntnmdx/introswell.mdx"))),
  "component---src-cntnmdx-lrw-1-mdx": hot(preferDefault(require("/home/documentationV2/demo/src/cntnmdx/lrw1.mdx"))),
  "component---src-cntnmdx-lrw-2-mdx": hot(preferDefault(require("/home/documentationV2/demo/src/cntnmdx/lrw2.mdx"))),
  "component---src-cntnmdx-modularkey-mdx": hot(preferDefault(require("/home/documentationV2/demo/src/cntnmdx/modularkey.mdx"))),
  "component---src-cntnmdx-oracleless-mdx": hot(preferDefault(require("/home/documentationV2/demo/src/cntnmdx/oracleless.mdx"))),
  "component---src-cntnmdx-restakerocket-mdx": hot(preferDefault(require("/home/documentationV2/demo/src/cntnmdx/restakerocket.mdx"))),
  "component---src-cntnmdx-restakeswell-mdx": hot(preferDefault(require("/home/documentationV2/demo/src/cntnmdx/restakeswell.mdx"))),
  "component---src-cntnmdx-stakeunstake-mdx": hot(preferDefault(require("/home/documentationV2/demo/src/cntnmdx/stakeunstake.mdx"))),
  "component---src-introducing-mdx": hot(preferDefault(require("/home/documentationV2/demo/src/introducing.mdx"))),
  "component---src-pages-404-js": hot(preferDefault(require("/home/documentationV2/demo/.docz/src/pages/404.js")))
}

