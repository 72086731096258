import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/documentationV2/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Hint } from '@nejcm/docz-theme-extended';
import { Tabs } from '@nejcm/docz-theme-extended';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-introducing-verse"
    }}>{`👋 Introducing Verse`}</h1>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`Verse permissionless vaults is a set of smart contracts that allows to create a multi-ERC20
token Vault and a Strategy on top of different DeFi protocols (like Uniswap, Yearn, etc.)`}</p>
    <Hint type="success" mdxType="Hint">
Verse support multiple assets to do both restaking / staking
    </Hint>
    <h2 {...{
      "id": "users"
    }}>{`Users`}</h2>
    <p>{`There are three types of actors using permissionless vaults:`}</p>
    <Tabs activeId="Liquidity provider" mdxType="Tabs">
  <Tabs.Tab id="Liquidity provider" title="Liquidity provider">
    Liquidity provider provides liquidity into Vaults to earn profits
  </Tabs.Tab>
  <Tabs.Tab id="Strategist" title="Strategist">
    Strategist manages liquidity in the Vaults by distributing it among different DeFi protocols. 
    Typically Strategist would create a Strategy smart contract that transparently manages Vault liquidity. 
    However, Strategy can also be an offline algorithm or manual liquidity management.
  </Tabs.Tab>
  <Tabs.Tab id="Governance" title="Governance">
    Tab content Governance manages common protocol parameters
  </Tabs.Tab>
    </Tabs>
    <h2 {...{
      "id": "vaults"
    }}>{`Vaults`}</h2>
    <p>{`Vaults are smart contracts that put liquidity into different underlying protocols.
The underlying protocol could be some well-known DeFi protocol like Uniswap, Sushiswap, Yearn, Compound, etc., or another Vault.
The tokens managed by Vault are fixed and immutable, i.e. `}</p>
    <p>{`Vault cannot start managing additional tokens or stop managing existing tokens.
Each Vault can only put liquidity into one fixed underlying protocol.`}</p>
    <Hint type="info" variant="outer" mdxType="Hint">
The Strategy can only redistribute ERC-20 tokens between protocols. 
The tokens can leave the Vault only when the  Liquidity provider withdraws it.
    </Hint>
    <h2 {...{
      "id": "why-use-vaults"
    }}>{`Why use Vaults`}</h2>
    <p>{`The Liquidity provider would use vault to earn profits generated by the strategy.
Note that the Strategy generally bears some risks so the Liquidity provider may earn profits but can also incur losses depending on the Strategy behavior.
The liquidity can be provided and withdrawn at any time.`}</p>
    <p>{`The Strategist would earn performance fees from profits generated on the Vault liquidity.
The term profits generally refer to the profits of the underlying protocol like fees generated by Uniswap V3 or yield generated by Aave.
For example, profits don't capture any price movements in UniswapV3.`}</p>
    <h2 {...{
      "id": "what-does-verse-offer"
    }}>{`What Does Verse Offer?`}</h2>
    <p>{`Verse offers a suite of products that include Liquid Restaking Tokens (LRT) for ETH and ERC-20 Tokens.
Verse utilizes its universal standard to unlock liquidity and maximum value in PoS tokens with existing liquid staking tokens.`}</p>
    <h2 {...{
      "id": "security"
    }}>{`Security`}</h2>
    <p>{`Verse is committed to enhancing its products and services to guarantee the utmost quality in functionality and security.
All the security measures are published in security section.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      