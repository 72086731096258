import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/documentationV2/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-oracle-less"
    }}>{`🔐 Oracle-Less`}</h1>
    <p>{`Verse chose to relying more extensively on on-chain data to compute its estimated
validator balances and staking rewards, instead of using an oracle service that
utilizes off-chain data and calculations. `}</p>
    <p>{`The main reason for why oracles are necessary is because ETH 2.0 does not differentiate
withdrawal credential addresses so the oracle functions by running calculations off chain
to tell the smart contract which part of received ETH tokens that are received are
classified as full withdrawals, and not consensus rewards. The oracle also helps with
accounting for pending validators, stopped validators, and slashed validators. `}</p>
    <p>{`In essence, utilizing oracles help with estimating the validator balance and rewards
sum because there is no direct way to do it with ETH 2.0. It is worth noting that using
oracles carry inherent risks such as its service being compromised or malicious actors getting access to it. `}</p>
    <p>{`This is a huge milestone for Verse as it has mitigated the risk of inaccurate off-chain
calculations and the risk of potential compromises to the oracle service. Instead, Verse
is able to get the native, on-chain reward and balance estimates for the validators that
are deployed, thus ensuring a peace of mind for all our staking customers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      