import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/documentationV2/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Ref } from '@nejcm/docz-theme-extended';
import { Hint } from '@nejcm/docz-theme-extended';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-liquid-restaking-with-rocketpool"
    }}>{`🚀 Liquid Restaking with Rocketpool`}</h1>
    <Hint type="warning" mdxType="Hint">
New Rocketpool LRT Vault in Development
    </Hint>
    <p>{`Liquid restaking is a combination of restaking on Rocketpool with liquid staking in a single token.
This keeps liquidity with our partner booster multiplier intact while enabling rETH holders to profit from both ETH staking and restaking on Verse.`}</p>
    <Ref name="Learn more here" to="/cntnmdx/introrocket" mdxType="Ref" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      