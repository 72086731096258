import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/documentationV2/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Hint } from '@nejcm/docz-theme-extended';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-restaked-points-rocketpool"
    }}>{`💪 Restaked Points Rocketpool`}</h1>
    <p>{`Restaked points are a measure of your contribution to the shared security of the Rocketpool ecosystem. They represent staking participation equal to the time-integrated amount staked.`}</p>
    <p>{`For example, a user who stakes 1 ETH for 1 hour accrues 30 restaked points and 0.0002 rETH.`}</p>
    <p>{`Verse distributes 100% of the restaking points earned by its users' deposits`}</p>
    <Hint type="warning" mdxType="Hint">Note: There is, approximately, a 24-hour delay in earning Rocketpool Restaked points to account for the Ethereum nodes being spun up and provisioned. This applies both for minting and transferring.
    </Hint>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      