import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/documentationV2/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Tag } from '@nejcm/docz-theme-extended';
import { Hint } from '@nejcm/docz-theme-extended';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-introduction-to-rocketpool"
    }}>{`🔍 Introduction to Rocketpool`}</h1>
    <p>{`Rocket Pool is a decentralized Ethereum staking platform designed to enable users to stake their ETH in a more decentralized and secure manner.
Unlike some other staking services, Rocket Pool emphasizes decentralization by allowing anyone to run their own node and participate in staking,
while also offering liquid staking solutions. `}<br /></p>
    <Tag type="info" mdxType="Tag">Key Features of Rocketpool:</Tag>  <br />
    <h2 {...{
      "id": "staking-pools"
    }}>{`Staking Pools`}</h2>
    <p>{`Rocket Pool aggregates smaller ETH deposits into staking pools, which are then managed by decentralized node operators. This pooling mechanism
allows anyone to participate in Ethereum staking without needing to run their own node.`}</p>
    <h2 {...{
      "id": "permissionless-node-operation"
    }}>{`Permissionless Node Operation`}</h2>
    <p>{`Rocket Pool allows anyone with 8 ETH and some technical knowledge to run a node. By staking a minimum of 8 ETH and receiving 8 ETH from the pool
(totaling 16 ETH), node operators contribute to the security and decentralization of the Ethereum network`}</p>
    <h2 {...{
      "id": "node-commission"
    }}>{`Node Commission`}</h2>
    <p>{`Node operators on Rocket Pool can set a commission fee that they earn for running the infrastructure needed to stake ETH. This creates an incentive
for more users to become node operators, further decentralizing the network.`}</p>
    <h2 {...{
      "id": "smart-contract-audits-and-security"
    }}>{`Smart Contract Audits and Security`}</h2>
    <p>{`Rocket Pool smart contracts undergo regular audits to ensure the safety of user funds. Security is a top priority, and the platform strives for a
trustless, decentralized system to minimize risks.`}</p>
    <Tag type="info" mdxType="Tag">Key Advantages:</Tag> <br />
    <h2 {...{
      "id": "accessibility"
    }}>{`Accessibility`}</h2>
    <p>{`With a minimum staking requirement of 0.01 ETH for regular users, Rocket Pool is highly accessible to small ETH holders. Additionally, the 8 ETH
requirement for node operators is lower than the 32 ETH needed to run a validator independently.`}</p>
    <h2 {...{
      "id": "decentralization"
    }}>{`Decentralization`}</h2>
    <p>{`Rocket Pool’s decentralized network of node operators strengthens the Ethereum network by reducing the risks of centralization associated with
large staking pools and centralized exchanges.`}</p>
    <h2 {...{
      "id": "reth-token-liquidity"
    }}>{`rETH Token Liquidity`}</h2>
    <p>{`The rETH token can be used in the broader DeFi ecosystem, providing liquidity to stakers and allowing them to earn additional yield from DeFi
protocols while still benefiting from staking rewards.`}</p>
    <h2 {...{
      "id": "node-operator-incentives"
    }}>{`Node Operator Incentives`}</h2>
    <p>{`By allowing individuals to run their own nodes and earn rewards, Rocket Pool decentralizes staking and provides financial incentives for
decentralized participation.`}</p>
    <h2 {...{
      "id": "slashing-risk-mitigation"
    }}>{`Slashing Risk Mitigation`}</h2>
    <p>{`The RPL token bond system ensures that stakers are protected from the financial risks of slashing penalties, making Rocket Pool a safer option for
both stakers and node operators.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      