import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/documentationV2/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Tag } from '@nejcm/docz-theme-extended';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-fees"
    }}>{`💰 Fees`}</h1>
    <h2 {...{
      "id": "fee-mechanism"
    }}>{`Fee mechanism`}</h2>
    <Tag type="success" mdxType="Tag">1. 2.5% commission on block rewards, transaction and MEV fees</Tag> <br />
    <Tag type="success" mdxType="Tag">2. All points will be distributed to holders</Tag> <br />
    <Tag type="success" mdxType="Tag">3. Commission on Fees from restaking to be decided by the community</Tag>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      